<template>
  <b-card title="Novedades">

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Buscar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Buscar"
            type="text"
            class="d-inline-block"
          />
          <b-spinner
            v-show="loading"
            variant="primary"
            label="Loading..."
            class="ml-1"
          />
          <b-button
            v-show="!loading"
            class="ml-1"
            variant="primary"
            @click="downloadNewness"
          >
            <span class="text-nowrap">Descargar novedades</span>
          </b-button>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="productNewness"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span
          v-if="props.column.field === 'newness_type'"
        >
        {{ props.row.newness_type.name }} - {{ props.row.description }}
        </span>

        <span
          v-else-if="props.column.field === 'created_at'"
        >
        {{ formatDateVariant(props.row.created_at) }}
        </span>


        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item 
                @click="removeNewness(props.row.id)"
                >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50 text-danger"
                />
                <span>Remover</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="[1].includes(userData.user_profile_id)"
                :to="{
                  name: 'traceability', 
                  params: { 
                    loggable_type: 'pedido',
                    loggable_id: props.row.id,
                  }
                }">
                <feather-icon
                  icon="ActivityIcon"
                  class="mr-50"
                />
                <span>Trazabilidad</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item
                :to="{ name: 'order-detail', params: { order_id: props.row.id }}">
                <feather-icon
                  icon="ListIcon"
                  class="mr-50"
                />
                <span>Detalle</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 a
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['20','40','60']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} items </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import {constants} from "@/helpers/constants"
import {formatDate} from "@/helpers/utils"
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      userData: JSON.parse(localStorage.getItem('userData')),
      loading: false,
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Serial',
          field: 'product.serial',
        },
        {
          label: 'Tipo serial',
          field: 'product.product_type.name',
        },
        {
          label: 'Número de transporte',
          field: 'product.number_order',
        },
        {
          label: 'Pedido',
          field: 'order.code',
        },
        {
          label: 'Tipo novedad',
          field: 'newness_type',
        },
        {
          label: 'Creado',
          field: 'created_at',
        },
        {
          label: 'Acciones',
          field: 'action',
        },
      ],
      productNewness: [],
      searchTerm: '',
      status: [{
        1: 'En alistamiento',
        2: 'Despachado',
        3: 'Cancelado',
        4: 'Finalizado',
        5: 'Recibido',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      removeNewnessModal: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'En alistamiento'      : 'light-primary',
        Despachado : 'light-success',
        Cancelado     : 'light-danger',
        Finalizado     : 'light-warning',
        Recibido      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {
    this.getNewnesses()
  },
  methods: {

    getNewnesses() {

      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/product-newness', { headers }).then(response => {
        console.log('product-newness: ', response)

        this.productNewness = response.data.data.product_newness
        
      }).catch( err => {
        console.log(err)
      })

    },

    removeNewness(newnessId) {

      this.$bvModal
        .msgBoxConfirm('Por favor confirme si quiere eliminar el serial de novedades.', {
          title: 'Eliminar novedad',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          console.log('eliminar')

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.delete(this.host + '/api/front-web/remove-newness/' + newnessId, { headers }).then(response => {
            console.log('remove-newnesses: ', response)

            if(response.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })

              this.getNewnesses()
              
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }

          }).catch( err => {
            console.log(err)
          })

        })

    },

    downloadNewness() {

      this.loading = true
      const vm = this

      let xhr = new XMLHttpRequest();
      xhr.open('post', this.host + '/api/front-web/download-newness', true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        vm.loading = false
        if (this.status === 200) {
          let date = moment().format('DD-MM-YYYY HH:mm:ss');

          let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'novedades-' + date + '.xlsx';
          link.click();
          
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se ha descargado el archivo',
              icon: 'SlashIcon',
              variant: 'warning',
            },
          }) 
        }
      };
      xhr.send()
      xhr.th

    },

  },
}
</script>
