var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Novedades"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Buscar")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Buscar","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"ml-1",attrs:{"variant":"primary","label":"Loading..."}}),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":_vm.downloadNewness}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Descargar novedades")])])],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.productNewness,"rtl":_vm.direction,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm },"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage:_vm.pageLength
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'newness_type')?_c('span',[_vm._v(" "+_vm._s(props.row.newness_type.name)+" - "+_vm._s(props.row.description)+" ")]):(props.column.field === 'created_at')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateVariant(props.row.created_at))+" ")]):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.removeNewness(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50 text-danger",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Remover")])],1),([1].includes(_vm.userData.user_profile_id))?_c('b-dropdown-item',{attrs:{"to":{
                name: 'traceability', 
                params: { 
                  loggable_type: 'pedido',
                  loggable_id: props.row.id,
                }
              }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ActivityIcon"}}),_c('span',[_vm._v("Trazabilidad")])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Mostrando 1 a ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['20','40','60']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" items ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }